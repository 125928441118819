import axios from './utils';

interface User {
 username: string;
 email: string;
 phone: string;
}

interface FetchUserResponse {
 username: string | null;
}

export const registerUser = async (user: User) => axios.post<undefined>('/users', { user });

export const fetchUser = async (username: string) => 
 axios.get<FetchUserResponse>(`/users/${username}`)
  .then(res => res.data);